<template>
  <!--eslint-disable vue/camelcase-->
  <div>
    <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="bulkUpdateForm"
    >
      <form
          class="authentication-form"
          @submit.prevent="handleSubmit(submitFile)"
      >
        <div class="row">
          <div class="col">
            <a :href="csvDownloadLink" class="mb-2 btn btn-outline-primary btn-sm">Download Sample</a>
            <div class="form-group">
              <label>Max Daily Send Limit
                <span v-b-tooltip.hover title="If you have 100 leads and set a limit of 10, it will take 10 days to send out your leads. Do not set a limit to process all records immediately. The specific sending schedule is set in your Contact Series Settings." class="copy-icon pl-2">
                  <i class="uil uil-question-circle" />
                </span>
              </label>
              <b-input v-model.number="uploadLimit" min="1" type="number" class="form-control max-upload" placeholder="No Limit"  />
            </div>
            <input ref="fileInput" type="file" name="bulk" class="d-block" @change="setFile"/>
            <b-button class="mt-3" size="md" type="submit"><b-spinner v-if="loadingUpdate" small />Upload</b-button>
          </div>
        </div>
        <div v-if="results.length" class="mt-4">
          <strong class="d-block mb-2">Results:</strong>
          <ul class="list-unstyled">
            <li v-for="(row,i) in results" :key="i">
              {{row}}
            </li>
          </ul>
        </div>
      </form>
      <div v-if="retrieving_table_data" class="text-center">
        <b-spinner small /> Retrieving Uploads
      </div>
      <div v-else>
        <div v-if="reviewContacts.length > 0">
          <h4 class="mt-5">Recent Uploads</h4>
          <b-table
            :items="reviewContacts"
            :fields="requestContactsDatatable.columns"
            :sort-by.sync="requestContactsDatatable.queries.sort_by"
            :sort-desc.sync="requestContactsDatatable.queries.sort_desc"
            :per-page="requestContactsDatatable.queries.per_page"
            :no-local-sorting="true"
            :borderless="true"
            :striped="true"
        >
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <template v-slot:cell(review_request_series)="row">
            <table class="table table-bordered">
              <tr>
                <td>Series</td>
                <td>{{row.item.review_request_series.map(o => o.name).join(', ')}}</td>
              </tr>
            </table>
          </template>
          <template v-slot:cell(finished)="row">
            <span v-if="row.item.deleted_at || row.item.finished === 'true'">true</span>
            <span v-else>false</span>
          </template>
          <template v-slot:cell(id)="row" style="width:200px">
            <b-button size="sm" @click="row.item.modal_open = true">More Info</b-button>
            <b-modal v-model="row.item.modal_open" :hide-footer="true" :title="row.item.name">
              <table class="table table-bordered">
                <tr>
                  <td>Series</td>
                  <td>{{row.item.review_request_series.map(o => o.name).join(', ')}}</td>
                </tr>
                <tr>
                  <td>Next Run Time</td>
                  <td>
                    <span v-if="row.item.finished">Finished</span>
                    <span v-else-if="row.item.next_series_process_time_front_date !== null">
                    {{row.item.next_series_process_time_front_date}}
                  </span>
                    <span v-else>Pending</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-center bg-primary text-white">Visits</td>
                </tr>
                <tr>
                  <td colspan="2" class="p-0">
                    <table v-if="row.item.visits && row.item.visits.length" class="table w-100 table-striped">
                      <thead>
                      <tr>
                        <th>Type</th>
                        <th>Step</th>
                        <th>Time</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="visit in row.item.visits" :key="visit.id">
                        <td>{{visit.type}}</td>
                        <td>{{visit.step}}</td>
                        <td>{{visit.front_date}}</td>
                      </tr>
                      </tbody>
                    </table>
                    <div v-else class="text-center p-2">None</div>
                  </td>
                </tr>
              </table>
            </b-modal>
          </template>
          <template v-slot:cell(content)="row">
            <!-- eslint-disable-next-line-->
            <div v-html="row.item.nl2br_html"></div>
          </template>
        </b-table>
        </div>
      </div>
      <div v-if="!retrieving_table_data && reviewContacts.length" class="row mt-4">
        <div class="col">
          <div class="float-left mt-2">
            Total {{ totalContacts }}
          </div>
          <div
              class="dataTables_paginate paging_simple_numbers float-right"
          >
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                  v-model="requestContactsDatatable.queries.page"
                  :total-rows="totalContacts"
                  :per-page="requestContactsDatatable.queries.per_page"
                  @change="onReviewRequestLogPageChanged"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>

</template>

<script>

import Vue from "vue";
export default {
  components:{
  },
  data(){
    return {
        user:null,
        business:null,
        loadingUpdate:false,
        fileUpload:null,
        uploadLimit:null,
        results:[],
        retrieving_table_data:false,
        requestContactsDatatable: {
          items: [],
          columns: [
            { key: 'front_date', label:'Created', sortable: true},
            { key: 'first_name', label:'First Name', sortable: true },
            { key: 'last_name', label:'Last Name', sortable: true},
            { key: 'email', label:'Email', sortable: true},
            { key: 'cell_phone', label:'Cell Phone', sortable: true},
            { key: 'total_steps', label:'Total Steps'},
            { key: 'current_step', label:'Current Step'},
            { key: 'finished', label:'Finished'},
            { key: 'id', label:''},
          ],
          queries: {
            per_page: 10,
            page: 1,
            status: 'active',
            search: null,
            sort_by: 'created_at',
            sort_desc: false,
            is_bulk:true
          },
        }
    }
  },
  computed:{
    singleCreateUrl(){
      return window.location.origin + '/request-reviews/' + this.user.business.unique_id + '-' + this.user.business.id;
    },
    csvDownloadLink(){
      return  (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'bulk-contact-request-sample';
    },
    reviewContacts(){
      return this.$store.getters['contactRequests/all'];
    },
    totalContacts(){
      return this.$store.getters['contactRequests/total'];
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getContacts();
  },
  methods:{
    getContacts(){
      this.retrieving_table_data = true;
      this.$store
          .dispatch(`contactRequests/getAll`, {
            queries: this.requestContactsDatatable.queries,
            page: 'contacts',
            businessId: this.user.business.id
          })
          .then((resp) => {
            console.log(resp);
            this.retrieving_table_data = false;
          })
          .catch((err) => {
            this.retrieving_table_data = false;
            console.error(err);
          })
    },
    onReviewRequestLogPageChanged(page) {
      this.requestContactsDatatable.queries.page = page
      this.getContacts();
    },
    setFile(){
      this.fileUpload = event.target.files[0];
    },
    submitFile(){
      this.loadingUpdate = true

      let uploadForm = new FormData();
      uploadForm.append('csv', this.fileUpload);
      uploadForm.append('uploadLimit',this.uploadLimit);
      uploadForm.append('business_id',this.user.business.id);
      this.$store
          .dispatch('business/bulkAddReviews', uploadForm)
          .then((resp) => {
            this.loadingUpdate = false
            this.fileUpload = null;
            this.$refs.fileInput.value = '';
            this.results = resp.data;
            if(resp.total > 0){
              this.getContacts();
            }
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.bulkUpdateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    }
  }
}
</script>
<style scoped>
.max-upload{max-width: 100px}
</style>
<style>
.review-admin-contain{
  /*margin-top: -10px;
  margin-left:-24.5px;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;*/
}
body{
  padding-bottom: 0;
}
.gate-left{
  background: #284373;
  color:#fff;
}
.site-list li{
  background:#fff;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 10px;
  img{
    max-width: 100px;
    height: auto;
  }
}
.handle-icon{
  cursor: move;
}
.eyebtn{
  margin-top: -5px;
}
</style>
